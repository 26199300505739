import Vue from 'vue'
import Router from 'vue-router'
/* Layout */
import Layout from '@/layout'

Vue.use(Router)

/**
 * Note: 路由配置项
 *
 * hidden: true                   // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true               // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect           // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'             // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * meta : {
    noCache: true                // 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    title: 'title'               // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'             // 设置该路由的图标，对应路径src/assets/icons/svg
    breadcrumb: false            // 如果设置为false，则不会在breadcrumb面包屑中显示
    activeMenu: '/system/user'   // 当路由设置了该属性，则会高亮相对应的侧边栏。
  }
 */

// 公共路由
export const constantRoutes = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: (resolve) => require(['@/views/redirect'], resolve)
      }
    ]
  },
  {
    path: '/login',
    component: (resolve) => require(['@/views/login'], resolve),
    hidden: true
  },
  {
    path: '/sso',
    component: (resolve) => require(['@/views/sso'], resolve),
    hidden: true
  },
  {
    path: '/social-login',
    component: (resolve) => require(['@/views/socialLogin'], resolve),
    hidden: true
  },
  {
    path: '/404',
    component: (resolve) => require(['@/views/error/404'], resolve),
    hidden: true
  },
  {
    path: '/401',
    component: (resolve) => require(['@/views/error/401'], resolve),
    hidden: true
  },
  {
    path: '/403',
    component: (resolve) => require(['@/views/error/403'], resolve),
    hidden: true
  },
  {
    path: '',
    component: Layout,
    redirect: 'index',
    children: [{
        path: 'index',
        component: (resolve) => require(['@/views/index'], resolve),
        name: '首页',
        meta: {title: '首页', icon: 'dashboard', affix: true}
      }
    ]
  }, {
    path: '/user',
    component: Layout,
    hidden: true,
    redirect: 'noredirect',
    children: [{
        path: 'profile',
        component: (resolve) => require(['@/views/system/user/profile/index'], resolve),
        name: 'Profile',
        meta: {title: '个人中心', icon: 'user'}
      }
    ]
  }, {
    path: '/dict',
    component: Layout,
    hidden: true,
    children: [{
        path: 'type/data/:dictId(\\d+)',
        component: (resolve) => require(['@/views/system/dict/data'], resolve),
        name: 'Data',
        meta: {title: '字典数据', icon: '', activeMenu: '/system/dict'}
      }
    ]
  }, {
    path: '/job',
    component: Layout,
    hidden: true,
    children: [{
        path: 'log',
        component: (resolve) => require(['@/views/infra/job/log'], resolve),
        name: 'JobLog',
        meta: {title: '调度日志', activeMenu: '/infra/job'}
      }
    ]
  }, {
    path: '/codegen',
    component: Layout,
    hidden: true,
    children: [{
        path: 'edit/:tableId(\\d+)',
        component: (resolve) => require(['@/views/infra/codegen/editTable'], resolve),
        name: 'GenEdit',
        meta: {title: '修改生成配置', activeMenu: '/infra/codegen'}
      }
    ]
  }, {
    path: '/bpm',
    component: Layout,
    hidden: true,
    redirect: 'noredirect',
    children: [{
        path: 'oa/leave/create',
        component: (resolve) => require(['@/views/bpm/oa/leave/create'], resolve),
        name: '发起 OA 请假',
        meta: {title: '发起 OA 请假', icon: 'form', activeMenu: '/bpm/oa/leave'}
      }, {
        path: 'oa/leave/detail',
        component: (resolve) => require(['@/views/bpm/oa/leave/detail'], resolve),
        name: '查看 OA 请假',
        meta: {title: '查看 OA 请假', icon: 'view', activeMenu: '/bpm/oa/leave'}
      }
    ]
  }, {
    path: '/bpm',
    component: Layout,
    hidden: true,
    children: [{
        path: 'manager/form/edit',
        component: (resolve) => require(['@/views/bpm/form/formEditor'], resolve),
        name: '流程表单-编辑',
        meta: {title: '流程表单-编辑', activeMenu: '/bpm/manager/form'}
      }, {
        path: 'manager/definition',
        component: (resolve) => require(['@/views/bpm/definition/index'], resolve),
        name: '流程定义',
        meta: {title: '流程定义', activeMenu: '/bpm/manager/model'}
      }, {
        path: 'manager/model/design',
        component: (resolve) => require(['@/views/bpm/model/modelEditor'], resolve),
        name: '设计流程',
        meta: {title: '设计流程', activeMenu: '/bpm/manager/model'}
      }, {
        path: 'process-instance/create',
        component: (resolve) => require(['@/views/bpm/processInstance/create'], resolve),
        name: '发起流程',
        meta: {title: '发起流程', activeMenu: '/bpm/task/my'}
      }, {
        path: 'process-instance/detail',
        component: (resolve) => require(['@/views/bpm/processInstance/detail'], resolve),
        name: '流程详情',
        meta: {title: '流程详情', activeMenu: '/bpm/task/my'}
      }
    ]
  },
  {
    path: '/system',
    component: Layout,
    hidden: true,
    children: [{
        path: 'dict/index/',
        component: (resolve) => require(['@/views/system/dict/index'], resolve),
        name: '字典管理',
        meta: {title: '字典管理', icon: ''}
    },
    ]
  },
  {
    path: '/trace',
    component: Layout,
    hidden: true,
    children: [{
        path: 'zp/index/:batch',
        component: (resolve) => require(['@/views/trace/zzglTissueculture/index'], resolve),
        name: '组培管理',
        meta: {title: '组培管理', icon: '', activeMenu: '/zzglRecovery/index'}
      },{
        path: 'plant/index/:batch',
        component: (resolve) => require(['@/views/trace/zzglPlanting/index'], resolve),
        name: '种植管理',
        meta: {title: '种植管理', icon: '', activeMenu: '/zzglRecovery/index'}
      },
      {
        path: 'tianjian/index/:batch',
        component: (resolve) => require(['@/views/trace/infoPlant/index'], resolve),
        name: '田间管理',
        meta: {title: '田间管理', icon: '', activeMenu: '/zzglRecovery/index'}
      },
      {
        path: 'zzglPlanting/tjcgindex/:plantid',
        component: (resolve) => require(['@/views/trace/zzglPlanting/tjcsindex'], resolve),
        name: '田间采收管理',
        meta: {title: '田间采收管理', icon: '', activeMenu: '/zzglPlanting/index'}
      },
      {
        path: 'tianjian/index/',
        component: (resolve) => require(['@/views/trace/infoPlant/index'], resolve),
        name: '田间管理',
        meta: {title: '田间管理', icon: '', activeMenu: '/zzglRecovery/index'}
      },
      {
        path: 'infoEmployee/index/',
        component: (resolve) => require(['@/views/trace/infoEmployee/index'], resolve),
        name: '员工管理',
        meta: {title: '员工管理', icon: '', activeMenu: '/zzglRecovery/index'}
      },
      {
        path: 'infoGongxu/index/',
        component: (resolve) => require(['@/views/trace/infoGongxu/index'], resolve),
        name: '工序信息管理',
        meta: {title: '工序信息管理', icon: '', activeMenu: 'trace/infoGongxu/index'}
      },
      {
        path: 'infoTeam/index/',
        component: (resolve) => require(['@/views/trace/infoTeam/index'], resolve),
        name: '班组信息管理',
        meta: {title: '班组信息管理', icon: '', activeMenu: 'trace/infoTeam/index'}
      },
      {
        path: 'infoMerchants/index/',
        component: (resolve) => require(['@/views/trace/infoMerchants/index'], resolve),
        name: '客商信息管理',
        meta: {title: '客商信息管理', icon: '', activeMenu: 'trace/infoMerchants/index'}
      },
      {
        path: 'infoBase/index/',
        component: (resolve) => require(['@/views/trace/infoBase/index'], resolve),
        name: '基地管理',
        meta: {title: '基地管理', icon: '', activeMenu: '/zzglRecovery/index'}
      },
      {
        path: 'infoSupplier/index/',
        component: (resolve) => require(['@/views/trace/infoSupplier/index'], resolve),
        name: '供应商管理',
        meta: {title: '供应商管理', icon: '', activeMenu: '/zzglRecovery/index'}
      },
      {
        path: 'infoseed/index/',
        component: (resolve) => require(['@/views/trace/infoSeed/index'], resolve),
        name: '种子管理',
        meta: {title: '种子管理', icon: '', activeMenu: '/zzglRecovery/index'}
      },
      {
        path: 'infoTeam/index/',
        component: (resolve) => require(['@/views/trace/infoTeam/index'], resolve),
        name: '劳动班组管理',
        meta: {title: '劳动班组管理', icon: '', activeMenu: '/zzglRecovery/index'}
      },
      {
        path: 'infoSc/index/',
        component: (resolve) => require(['@/views/trace/infoSc/index'], resolve),
        name: '生产方式管理',
        meta: {title: '生产方式管理', icon: '', activeMenu: '/zzglRecovery/index'}
      },
      {
        path: 'infoRouting/index/',
        component: (resolve) => require(['@/views/trace/infoRouting/index'], resolve),
        name: '工艺路线管理',
        meta: {title: '工艺路线管理', icon: '', activeMenu: '/zzglRecovery/index'}
      },
      {
        path: 'infoWorkshop/index/',
        component: (resolve) => require(['@/views/trace/infoWorkshop/index'], resolve),
        name: '加工单元管理',
        meta: {title: '加工单元管理', icon: '', activeMenu: '/zzglRecovery/index'}
      },
      {
        path: 'infoBase/index/',
        component: (resolve) => require(['@/views/trace/infoBase/index'], resolve),
        name: '基地管理',
        meta: {title: '基地管理', icon: '', activeMenu: '/zzglRecovery/index'}
      },
      {
        path: 'zzglTissueculture/index/',
        component: (resolve) => require(['@/views/trace/zzglTissueculture/index'], resolve),
        name: '组培管理',
        meta: {title: '组培管理', icon: '', activeMenu: '/zzglRecovery/index'}
      },
      {
        path: 'infoStorehouse/index/',
        component: (resolve) => require(['@/views/trace/infoStorehouse/index'], resolve),
        name: '仓库管理',
        meta: {title: '仓库管理', icon: '', activeMenu: '/zzglRecovery/index'}
      },
      {
        path: 'infoRouting/index/:routing',
        component: (resolve) => require(['@/views/trace/infoRouting/index'], resolve),
        name: '工艺管理',
        meta: {title: '工艺管理', icon: '', activeMenu: '/zzglRecovery/index'}
      },
      {
        path: 'zsglBatch/index/:batch',
        component: (resolve) => require(['@/views/trace/zsglBatch/index'], resolve),
        name: '追溯码管理',
        meta: {title: '追溯码管理', icon: '', activeMenu: '/zzglRecovery/index'}
      },
      {
        path: 'zzglRecovery/index/:batch',
        component: (resolve) => require(['@/views/trace/zzglRecovery/index'], resolve),
        name: '采收管理',
        meta: {title: '采收管理', icon: '', activeMenu: '/zzglRecovery/index'}
      },
      {
        path: 'cjg/index/:batch',
        component: (resolve) => require(['@/views/trace/jgglCjg/index'], resolve),
        name: '初加工管理',
        meta: {title: '初加工管理', icon: '', activeMenu: '/zzglRecovery/index'}
      },
      {
        path: 'sjg/index/:batch',
        component: (resolve) => require(['@/views/trace/jgglSjg/index'], resolve),
        name: '深加工管理',
        meta: {title: '深加工管理', icon: '', activeMenu: '/zzglRecovery/index'}
      },
      {
        path: 'stockin/index/:batch',
        component: (resolve) => require(['@/views/trace/kcglStockDetail/index'], resolve),
        name: '入库管理',
        meta: {title: '入库管理', icon: '', activeMenu: '/zzglRecovery/index'}
      },
      {
        path: 'salesout/index/:batch',
        component: (resolve) => require(['@/views/trace/xsXinxi/index'], resolve),
        name: '出库管理',
        meta: {title: '出库管理', icon: '', activeMenu: '/zzglRecovery/index'}
      },
      {
        path: 'zfjgtenant/index/:tenantName',
        component: (resolve) => require(['@/views/trace/zsglTenant/index'], resolve),
        name: '租户码管理',
        meta: {title: '租户码管理', icon: '', activeMenu: '/zzglRecovery/index'}
      }
    ]
  },{
    path: '/bigscreen',
    component: Layout,
    hidden: true,
    children: [
      {
        path: 'bozhoudetail/index/',
        component: (resolve) => require(['@/views/bigscreen/sczzBozhouDetail/index'], resolve),
        name: '亳州种植详情',
        meta: {title: '亳州种植详情', icon: '', activeMenu: '/bigscreen/sczzBozhou/index'}
      },
      {
        path: 'plant/index/:landId',
        component: (resolve) => require(['@/views/bigscreen/sczzPlant/index'], resolve),
        name: '地块种植详情',
        meta: {title: '地块种植详情', icon: '', activeMenu: '/bigscreen/land/index'}
      },
      {
        path: 'map/:id(\\d+)',
        component: (resolve) => require(['@/views/bigscreen/sczzMarkerinfo/map'], resolve),
        name: '编辑经纬度',
        meta: {title: '编辑经纬度', icon: '', activeMenu: '/bigscreen/land/index'}
      },
    ]
  },
  {
    path: '/zjzx',
    component: Layout,
    hidden: true,
    children: [{
        path: 'responses/index/: id',
        component: (resolve) => require(['@/views/online/responses/index'], resolve),
        name: '问题回复',
        meta: {title: '问题回复', icon: '', activeMenu: '/startQuest/index'}
      },
    ]
  },
]

// 防止连续点击多次路由报错
let routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err)
}

export default new Router({
  base: process.env.VUE_APP_APP_NAME ? process.env.VUE_APP_APP_NAME : "/",
  mode: 'history', // 去掉url中的#
  scrollBehavior: () => ({y: 0}),
  routes: constantRoutes
})
